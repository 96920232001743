import { Card } from "@/components/ui/card";
import { api } from "../../convex/_generated/api";
import { useMutation, useQuery } from "convex/react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { toast } from "@/hooks/use-toast";

const GroupPage = () => {
  const groups = useQuery(api.groups.getGroups);
  const joinGroup = useMutation(api.groups.join);

  const [groupCode, setGroupCode] = useState("");

  const handleJoinGroup = async () => {
    try {
      await joinGroup({ code: groupCode });
      setGroupCode("");
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "Invalid group code",
        variant: "destructive",
      });
    }
  };

  console.log(groups);
  return (
    <div className="p-4 max-w-xl mx-auto">
      <Card className="p-3 rounded-3xl sm:mt-8 flex items-center justify-between">
        <Input
          maxLength={6}
          placeholder="Group Code"
          className="rounded-l-xl rounded-r-none"
          value={groupCode}
          onChange={(e) => setGroupCode(e.target.value)}
        />
        <Button disabled={groupCode.length !== 6} className="rounded-r-xl rounded-l-none" onClick={handleJoinGroup}>
          Join Group
        </Button>
      </Card>
      <div className="pt-5 sm:pt-8">
        <h1 className="text-xl font-bold p-3 pb-1">My Groups</h1>
        {groups?.map((group) => (
          <Card className="p-5 rounded-2xl mt-2 border-2 border-primary-400" key={group?._id}>
            <p className="font-semibold text-primary-600">{group?.name}</p>
          </Card>
        ))}
        {groups?.length === 0 && (
          <div className="text-sm text-stone-500 mt-2 leading-relaxed px-3">
            You haven't joined any groups yet. Please reach out to{" "}
            <a className="underline" href="mailto:contact@superhabits.app">
              contact@superhabits.app
            </a>{" "}
            if you'd like to create a group for your organization.
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupPage;
