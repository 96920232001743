import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import HabitCard from "@/components/HabitCard";
import ScoreCard from "@/components/ScoreCard";
import { Card, CardContent } from "@/components/ui/card";
import { Link } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";
import { Button } from "@/components/ui/button";

const Dashboard = () => {
  const habits = useQuery(api.habits.get);
  const mostRecentAssessmentDate = useQuery(api.assessments.getMostRecentAssessmentDate);
  const isTwoWeeksSinceLastAssessment = mostRecentAssessmentDate
    ? new Date(mostRecentAssessmentDate).getTime() < Date.now() - 14 * 24 * 60 * 60 * 1000
    : true;
  const promptAssessment = isTwoWeeksSinceLastAssessment;

  if (habits === undefined) {
    return (
      <div className="flex items-center justify-center h-screen">
        <HashLoader color={"#925743"} loading={true} size={6} aria-label="Loading..." data-testid="loader" />
      </div>
    );
  }

  return (
    <div className="space-y-6 p-4 pt-1 sm:pt-12 max-w-5xl mx-auto">
      <div className="grid gap-3 sm:gap-4 sm:grid-cols-2">
        {!!promptAssessment && (
          <Card className="bg-cyan-600 shadow-lg shadow-cyan-600/20 border-4 border-cyan-500">
            <CardContent className="flex flex-col justify-center items-center space-y-4 h-full p-6">
              <h2 className="text-center text-lg font-semibold text-white">It's time for your next assessment!</h2>
              <Link to="/assessment">
                <Button className="bg-white text-cyan-600 hover:bg-cyan-50 rounded-full px-8">Get Started</Button>
              </Link>
            </CardContent>
          </Card>
        )}
        {habits?.length ? (
          <div className="flex flex-col gap-3 sm:gap-4">{habits?.map((habit) => <HabitCard key={habit._id} habit={habit} />)}</div>
        ) : (
          <Card className="flex flex-col border-dashed items-center justify-center space-y-3 text-center p-8">
            <h2 className="text-xl font-semibold">No habits yet</h2>
            <p className="text-muted-foreground text-sm">
              The{" "}
              <Link to="/messages" className="text-primary underline">
                AI coach
              </Link>{" "}
              can add a habit for you
            </p>
          </Card>
        )}
        <ScoreCard />
      </div>
    </div>
  );
};

export default Dashboard;
