import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getScoreColor = (score: number) => {
  if (score < 3) return "text-red-500";
  if (score >= 3 && score < 6) return "text-yellow-500";
  if (score >= 6) return "text-green-500";
  return "text-stone-500";
};
