import { CheckCheck, Flame } from "lucide-react";
import { Badge } from "./ui/badge";
import { Card, CardDescription, CardHeader, CardTitle } from "./ui/card";
// import HabitCalendar from "./HabitCalendar";
import { Doc } from "../../convex/_generated/dataModel";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { useMemo } from "react";
import { calculateStreak } from "../../convex/utils";
import HabitWeeklyCalendar from "./HabitWeeklyCalendar";

const HabitCard = ({ habit }: { habit: Doc<"habits"> }) => {
  const completions = useQuery(api.completions.get, { habitId: habit._id });
  const profile = useQuery(api.profiles.get);
  const streak = useMemo(() => calculateStreak(completions || [], profile?.timeZone || "America/New_York"), [completions, profile]);

  const getStreakColor = (streak: number) => {
    if (streak === 0) return "stone";
    if (streak < 3) return "blue";
    if (streak < 5) return "cyan";
    if (streak < 7) return "emerald";
    if (streak < 14) return "lime";
    if (streak < 21) return "amber";
    return "red";
  };

  const streakColor = getStreakColor(streak);

  return (
    <div className="relative">
      <Card className="">
        <CardHeader className="p-6 px-7">
          <div className="-mx-1 pb-2">
            <HabitWeeklyCalendar completions={completions || []} />
          </div>
          <CardTitle className="text-base font-semibold">{habit.name}</CardTitle>
          <CardDescription className="text-xs text-stone-500">{habit.description}</CardDescription>
          <div className="flex space-x-2 pt-2 -mx-1">
            <Badge className={`text-xs text-primary-600 whitespace-nowrap py-1.5 leading-none bg-primary-100`}>
              <CheckCheck size="14" className="mr-1 mb-[1px]" />
              Completions: {completions?.length}
            </Badge>
            <Badge
              className={`text-xs text-white whitespace-nowrap py-1.5 leading-none
            ${
              streakColor === "stone"
                ? "bg-stone-400"
                : streakColor === "blue"
                  ? "bg-blue-400"
                  : streakColor === "cyan"
                    ? "bg-cyan-400"
                    : streakColor === "emerald"
                      ? "bg-emerald-400"
                      : streakColor === "lime"
                        ? "bg-lime-400"
                        : streakColor === "amber"
                          ? "bg-amber-400"
                          : "bg-red-400"
            }`}
            >
              <Flame size="14" className="mr-1 mb-[1px]" />
              Streak: {streak}
            </Badge>
          </div>
        </CardHeader>
        {/* <CardContent>
          <HabitCalendar completions={completions || []} />
        </CardContent> */}
      </Card>
    </div>
  );
};

export default HabitCard;
